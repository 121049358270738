import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React from 'react'
import styled from 'styled-components'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import LinkExtended from './LinkExtended'

const AboutBannerWrapper = styled.div``

const AboutBannerChild = styled(FadeUpWhenVisible)`
  min-height: 127px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: rgb(0 0 0 / 30%);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 1068px;
    min-height: 257px;
  }
`

const AboutBannerTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 10px #000000;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 35px;
    line-height: 84px;
  }
`

const AboutBannerDescription = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 10px #000000;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 35px;
    line-height: 42px;
  }
`
const Button = styled(LinkExtended)`
  // position: absolute;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
  display: inline-block;
  border-radius: 12px;
  text-decoration: none;
  font-size: 17px;
  line-height: 20px;
  min-width: 142px;
  padding: 5px 10px;
  margin-top: 15px;
  transition: background-color 300ms ease;
  // top: 27vh;
  // left: 24.5vw;
  // height: 37px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 21px;
    min-width: 197px;
    padding: 10px 20px;
    margin-top: 10px;
  }
`

interface AboutBannerProps {
  title: React.ReactNode
  description?: React.ReactNode
  button?: React.ReactNode
}

const AboutBanner: React.FC<AboutBannerProps> = ({
  title,
  description,
  button,
}) => {
  const { placeholderImage } = getAboutBanner()
  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)
  return (
    <AboutBannerWrapper>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        alt="save-wagram-interieur"
        style={{
          overflow: 'hidden',
          borderRadius: '21px',
        }}
      >
        <AboutBannerChild>
          <AboutBannerTitle>{title}</AboutBannerTitle>
          {description && (
            <AboutBannerDescription>{description}</AboutBannerDescription>
          )}
          {button && <AboutBannerDescription>{button}</AboutBannerDescription>}
        </AboutBannerChild>
      </BackgroundImage>
    </AboutBannerWrapper>
  )
}

export default Object.assign(AboutBanner, {
  Button,
})
function getAboutBanner(): { placeholderImage: any } {
  return useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "save-wagram-interieur.png" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1068
              height: 257
              placeholder: BLURRED
              formats: [AVIF, WEBP]
            )
          }
        }
      }
    `
  )
}
